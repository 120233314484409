<template>
  
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import http from '../../common/bxhttp.js';
import {isNull,showMessage,nullStr} from '../../common/utils.js'
import KfCard from '../../components/KfCard.vue'
import ShareCard from '../../components/ShareCard.vue'
import { areaList } from '@vant/area-data'

let route=useRoute()
let router=useRouter()

let data=reactive({
    code:route.query.code,
    state:route.query.state 
    // state:localStorage.getItem("destIndex")
})

function searchCode(){
  localStorage.clear();
  http.post("/zbx/web/wx/check",{code:data.code}).then((res)=>{
        if(res.errcode=="0"){
          if(res.opentoken){
            localStorage.setItem("opentoken",res.opentoken);
            router.push({ path: "/login" });
            // location.href=data.state;
          }else{
            localStorage.setItem('token',res.token);
            localStorage.setItem('name',res.name);
            localStorage.setItem('type',res.type);
            localStorage.setItem('headimgurl',res.headimgurl);
            if(res.type=="U"&&!isNull(localStorage.getItem("shareProductCode"))&&!isNull(localStorage.getItem("shareOrderCode"))){
              router.push({ path: '/bxordershare',query:{"productCode":localStorage.getItem("shareProductCode"),"orderCode":localStorage.getItem("shareOrderCode")} })
            }else{

              location.href=data.state;
              // router.push({ path: "/" });
            }
            // localStorage.setItem("opentoken",res.opentoken);
            
            // location.href=data.state;
          }
        }else{
          showMessage(res.errmsg);
        }
  })
}

searchCode()


</script>
<style scoped lang="scss">

</style>